import React from 'react'

import Services from "../components/about";



function Home() {
  return (
    <>   
     {/* <Banner/> */}
    {/* <Promotions/> */}
    <Services/>
    
    
   
   
    </>

  )
}

export default Home
