
import {ServiceContainer, ServiceContent, ServiceDescription, ServiceImage,  } from "../../styles/style";
//import { ServiceImageContainer } from "../../styles/services2";
//import { Colors } from "../../styles/theme";



export default function Services(){
   // const theme = useTheme();

    return (
    <ServiceContainer>

<div style={{
        marginTop:'200px'
      }}></div>
            <ServiceContent>
             <ServiceImage 
                  src="/img/16.png">
                 </ServiceImage>
                 <ServiceDescription variant="subtitle">
                <p
                style={{
                    textAlign:'left',
                }}
                >Hi, I’m Paul.</p>
                <p>
                Building tech startups and teams, curating talents, creating opportunities for democratizing the 
                adoption of tech in unconventional markets. Currently based in Lagos, working via Delaware USA, 
                and Accra Ghana. Alumna of American Public University, Lagos Business School.
                </p>

                <p>
                Intrigued by wild nature, landscapes, space exploration, classical music, witchcraft, and even better conversations.

                </p>
                <p>
                Loved by two families of six, non-religious, neo-paganist Wiccan.

                </p>
                <p>
                Seeking to be inspired, to envision the unlikely, to champion the course of change, to work hard for things that are worth it, and to be surrounded by those who bring out the best in me.

                </p>
             


            <p>Say hi on Twitter @Paul_Royals</p>


               
  


            </ServiceDescription>
        </ServiceContent>
        

        

        
    </ServiceContainer>

    
    )
}