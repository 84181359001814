

import {  ServiceContainer, ServiceContent,  WorkDescription,  } from "../../styles/style";




export default function Footprints(){
   

    return (
    <ServiceContainer>
      <div style={{
        marginTop:'200px'
      }}></div>
        <ServiceContent>
           <WorkDescription variant="subtitle">
            <p>Paul Adeleke is a founder, leader, initiator, contributor, employee, and consultant to many organizations including:</p>
            <p>
                    <table style={{width:'70%'}}>
 
                    <tr>
                        <td>MCEC Nigeria</td>
                    </tr>
                    <tr>
                        <td>GoCanvas LLC USA</td>
                    </tr>
                    <tr>
                        <td>PG LLC USA, a Delaware Company</td>
                    </tr>
                    <tr>
                        <td>Comet Group</td>
                    </tr>
                    <tr>
                        <td>Moffat & Nicholl</td>
                    </tr>
                    
                  
                    <tr>
                        <td>TYDTal NG</td>
                    </tr>
                    <tr>
                        <td>MITSAN</td>
                    </tr>
                    
                    </table>

                  
             
            </p>


            <p> <strong>Past Projects:</strong></p>
            <p>
               

                    <table style={{width:'90%'}}>
 
                    <tr>
                        <td>TYDTal	Tech Talents Development</td>
                        <td>-</td>
                        <td>Year 2020</td>
                        
                    </tr>
                    <tr>
                        <td>Built a Tier-II Private Data Center in Lagos</td>
                        <td>-</td>
                         <td>Year 2018</td>
                       
                    </tr>

                    <tr>
                        <td> Text League Nigeria Games</td>
                        <td>-</td>
                        <td> Year 2009</td>
                        
                    </tr>
                    </table>
                  
             
            </p>
     
            <p> <strong>Social Projects:</strong></p>
            <p>
                
                    <table style={{width:'100%'}}>
                    <tr>
                    <td>Placing</td>
                   
                    </tr>
                    <tr>
                    <td>JOHICE Outreach</td>
                    <td>-</td>
                    <td>Nigeria Military Connected Children (2016 – 2018)</td>
                    </tr>
                    <tr>
                    <td>Cybersecurity Support Initiatives</td>
                    <td>-</td>
                    <td>Nigeria Police (2019)</td>
                    </tr>
                    
                    <tr>
                    <td>Lions Club</td>
                    <td>-</td>
                    <td>Member (2016 to date)</td>
                    </tr>
                    <tr>
                    <td>TechED Lagos	</td>
                    <td>-</td>
                    <td>(2014 – 2016) </td>
                    </tr>
                    <tr>
                    <td> Rice-for-Tech Campaign, Lagos Nigeria</td>
                    <td>-</td>
                    <td>NGO Initiatives by Riceland Ltd (2001 – 2004)</td>
                    </tr>
                    <tr>
                    <td> Rice-for-Tech Campaign, Lagos Nigeria</td>
                    <td>-</td>
                    <td>NGO Initiatives by Riceland Ltd (2001 – 2004)</td>
                    </tr>

                    <tr>
                    <td> Space Exploration by Tethered Satellites</td>
                    <td>-</td>
                    <td>Students OGTV Show 1998 - 1999</td>
                    </tr>


                   		

 

                    </table>
                    
                    </p>

                    <p> <strong>Academic Footprints:</strong></p>
                     <p>
                    <table style={{width:'70%'}}>
 
                    <tr>
                        <td>Lagos Business School</td>
                     </tr>
                     <tr>
                        <td>London Business School</td>
                     </tr>
                     <tr>
                        <td>American Public University</td>
                     </tr>
                     <tr>
                        <td>ISFOP Benin University</td>
                     </tr>
                     <tr>
                        <td>Lagos State University</td>
                     </tr>
                    
                    </table>
                    </p>

                    
                  

                    <p> <strong>Member Associations:</strong></p>
                    <p>
                    <table style={{width:'100%'}}>
                    	
 
 <tr>
   <td>BOS USA	</td>
   <td>Business of Software (Member)</td>
   
 </tr>
 <tr>
   <td>SaaS</td>
   <td>Saas Initiatives USA</td>
   
 </tr>

 <tr>
   <td>MITSAN	</td>
   <td>Maritime Information Technology Stakeholders Association of Nigeria</td>
   
 </tr>
 <tr>
   <td>CyberAfriq	</td>
   <td>African Cybersecurity Initiatives Forum</td>
   
 </tr>
 <tr>
   <td>ISACA</td>
   <td>Association for Certified Information Security & Auditors</td>
   
 </tr>

 <tr>
   <td>AITP</td>
   <td>Association of Information Technology Professionals</td>
   
 </tr>

 <tr>
   <td>  SCCE</td>
   <td>Society for Corporate Compliance & Ethics</td>
   
 </tr>

 <tr>
   <td>MCEC Nigeria</td>
   <td>(The Military Child Education Coalition Nigeria)</td>
   
 </tr>

 <tr>
   <td>AfriBlockChain</td>
   <td>Association of African Blockchain Enthusiast</td>
   
 </tr>

 <tr>
   <td>UNCTAD</td>
   <td>United Nations Conference on Trade and Development (NG Team/WF Member Contributor) – 2008/2009</td>
   
 </tr>

 <tr>
   <td>Agile Alliance</td>
   <td>Association of Agile Professionals (Member)</td>
   
 </tr>

 <tr>
   <td>Families First</td>
   <td>A family-first Initiatives</td>
   
 </tr>


 

                    </table>
                    </p>
                 

     

            </WorkDescription>
        </ServiceContent>
        

        

        
    </ServiceContainer>

    
    )
}