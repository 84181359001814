import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../theme";
import "@fontsource/poppins";
import "@fontsource/open-sans";



export const ServiceContainer = styled(Box)(({theme})=>({
        display: 'flex',
        flexDirection:'column',
        //justifyContent:'center',
        padding :'50px',
        backgroundColor:Colors.whitesmoke,

        [theme.breakpoints.down("sm")]:{
          width:'100%',
          margin:0,
          padding :0,
        
      
      }

}));

export const ServiceContent = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection:'row',
       // marginTop:'200px',

    justifyContent:'center',
    padding :'50px',
    alignItems:'center',
    marginLeft:'auto',
    marginRight:'auto',
    width:'80%',

    [theme.breakpoints.down("sm")]:{
      width:'100%',
      //marginLeft:'-50px',
      flexDirection:'column',
      marginLeft:0,
   
     
    
  }

   

}));


export const ServiceTitle = styled(Typography)(({theme})=>({

   fontSize:'30px',
   fontWeight:'400',
   color:Colors.black,
   fontFamily :'Poppins', 
   lineHeight:'64px',
   letterSpacing:'0.03em',
  // marginBottom:'16px',

  [theme.breakpoints.down("sm")]:{
    fontSize:'1em',
    fontWeight:'700',

}
}));

export const ServiceDescription = styled(Typography)(({theme})=>({

 
    fontSize:'13px',
  
    color:Colors.black,
    marginBottom:'3em',
    fontFamily: "Open Sans",
    fontStyle:'normal',
    fontWeight:'400',
    lineHeight:'1.6em',
    letterSpacing:'0',
    textAlign:'left',
    width:'70%',
  [theme.breakpoints.down("sm")]:{
    fontSize:'13px',
    fontWeight:'400',
    padding:0,
    width:'90%',
   marginRight:'90px'
}
 }));

 export const WorkDescription = styled(Typography)(({theme})=>({

 
  fontSize:'13px',

  color:Colors.black,
  marginBottom:'3em',
  fontFamily: "Open Sans",
  fontStyle:'normal',
  fontWeight:'400',
  lineHeight:'1.6em',
  letterSpacing:'0',
  textAlign:'left',
  width:'80%',
[theme.breakpoints.down("sm")]:{
  fontSize:'13px',
  fontWeight:'400',
  padding:0,
  width:'90%',
 marginRight:'90px'
}
}));

 export const ServiceImage = styled('img', Typography)(({src,theme})=>({
    src : `url(${src})`,
    width:' 180px',
    height:'180px',
    marginTop:'-100px',
    padding:'20px',
    borderRadius: '100%',
    objectFit:'cover',
  
         [theme.breakpoints.down("md")]:{
            width: '350px'
   
      },
      [theme.breakpoints.down("sm")]:{
         width: '80%',
         height: '80%',
         padding:'20px',
         paddingLeft:'15px',
         paddingRight:'15px',
         marginRight:'100px',
         borderRadius: '50%',
         
 
   }
 
   
  
      
  })) ;

 

