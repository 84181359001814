
import * as React from 'react';
import { ServiceImage } from '../styles/photo';
//import Box from '@mui/material/Box';

import { Container, Content, RowContainer } from '../styles/photo';

export default function Photo() {
  return (


    <Container>
      <div style={{
        marginTop:'230px'
      }}>

      </div>

      <Content >
    <RowContainer 
  
    >
      
      <ServiceImage 
   
   src="/img/8.jpg">
   
   </ServiceImage>
  
  

    <ServiceImage
  
   
   src="/img/5.jpg">

    </ServiceImage>

    <ServiceImage 
    src="/img/7.jpg">
    </ServiceImage>
    </RowContainer>
      </Content>


      <Content >
    <RowContainer 
  
    >
    <ServiceImage 
   
    src="/img/1.jpeg">
    </ServiceImage>

    <ServiceImage
  
   
   src="/img/2.jpeg">

    </ServiceImage>

    <ServiceImage 
    src="/img/4.jpeg">
    </ServiceImage>
    </RowContainer>
      </Content>
      <Content >
    <RowContainer 
  
    >
    <ServiceImage 
   
    src="/img/11.jpeg">
    </ServiceImage>

    <ServiceImage
  
   
   src="/img/12.jpeg">

    </ServiceImage>

    <ServiceImage 
    src="/img/3.jpeg">
    </ServiceImage>
    </RowContainer>
      </Content>
      <Content >
    <RowContainer 
  
    >
    <ServiceImage 
   
    src="/img/6.jpg">
    </ServiceImage>

    <ServiceImage
  
   
   src="/img/9.jpg">

    </ServiceImage>

    <ServiceImage 
    src="/img/10.jpg">
    </ServiceImage>
    </RowContainer>
      </Content>
     
      <Content >
    <RowContainer 
  
    >
    <ServiceImage 
   
    src="/img/paulinuk.jpg">
    </ServiceImage>

    <ServiceImage
   
   src="/img/paulatsmartports.jpg">

    </ServiceImage>

    <ServiceImage 
    src="/img/johiceng.jpg">
    </ServiceImage>
    </RowContainer>
      </Content>

<Content >
    <RowContainer 
  
    >
    <ServiceImage 
   
    src="/img/mcecnigeria.jpg">
    </ServiceImage>

    <ServiceImage
   
   src="/img/mc.jpg">

    </ServiceImage>

    <ServiceImage 
    src="/img/johice3.jpg">
    </ServiceImage>
    </RowContainer>
</Content>
<Content >
    <RowContainer 
  
    >
    <ServiceImage 
   
    src="/img/mcec.jpg">
    </ServiceImage>

    <ServiceImage
   
   src="/img/mce.jpg">

    </ServiceImage>

    <ServiceImage 
    src="/img/johice-items.jpg">
    </ServiceImage>
    </RowContainer>
</Content>
    </Container>
 
  
  );
}


