import { List, Typography } from "@mui/material";
import { styled} from "@mui/material/styles"
import { Box,  } from "@mui/system";
import { Colors } from "../theme";
import "@fontsource/montez"
import "@fontsource/questrial"; 
import { Link } from "react-router-dom";
import "fontsource-muli"; 
import "@fontsource/mulish";
import "@fontsource/open-sans";
import { NavLink } from "react-router-dom";






//container
export const  AppbarContainer = styled(Box)(()=>({
  position:'fixed',
  display : "flex",
  flexDirection:"column",
   justifyContent : 'center',
  alignItems : 'center',
   padding: '2px 8px',
  marginTop : 4,
  marginBottom: '300px',
  backgroundColor: Colors.whitesmoke,
  width: '100%',


}));

//header
export const  AppbarHeader = styled(Typography)(({theme})=>({
    padding : "5px",
   // marginTop:0,
   
    flexGrow : 1,
    marginTop:'20px',
    marginBottom:'50px',
    fontFamily: "Open Sans",
     fontWeight:'400',
    color:Colors.dim_gray,
    [theme.breakpoints.down('sm')]:{
       paddingLeft:'20px',
    }
  
    }));

    export const ServiceImage = styled('img', Typography)(({src,theme})=>({
      src : `url(${src})`,
      // width:' 134px',
      // height:'133px',
      // marginTop:'-200px',
      // padding:'20px',
    
           [theme.breakpoints.down("md")]:{
              width: '350px'
     
        },
        [theme.breakpoints.down("sm")]:{
           width: '84px',
           
   
     }
   
     
    
        
    })) ;

    //list
export const  MyList = styled(List)(({type})=>({
    display : type === 'row' ? 'flex': 'block',
    flexGrow : 1,
    justifyContent : 'center',
    alignItems : 'center',
    backgroundColor:Colors.white,
    color: Colors.black,
    padding:'20px',
    opacity: '0.9',
    borderRadius:'15px',
    fontFamily :'Questrial',
    fontSize:'180px',
   
    }));

    export const  Links = styled(Link)(({type})=>({
      fontSize:'10px',
      textDecoration:'none',
      color:'black'
     
      }));

    export const  Menulinks = styled(NavLink)(()=>({
      //display : "flex",

      fontSize:'12px',
      padding:'10px',
      fontWeight:'400',
      fontFamily: 'Mulish',
      //color:Colors.newgray,
      color:'black',
      textDecoration: 'none',
      lineHeight: '1em',
      textTransform:'uppercase',

     
    }));

    export const  MyList2 = styled(List)(()=>({
      display : 'row',
      flexGrow : 1,
      justifyContent : 'center',
      alignItems : 'center',
      backgroundColor:Colors.backcolor,
      color: Colors.white,
      padding:'20px',
      opacity: '0.9',
      borderRadius:'15px',
      fontFamily :'Questrial',
      fontSize:'180px',
     
      }));

      export const  ItemBox = styled(Box)(()=>({
        display : "flex",
        flexDirection:'row',
       // background: Colors.shaft,
       // position:"fixed",
        bottom: 0,
        left: 0,
        width:'100%',
        alignItem: 'center',
        zIndex: 99,
        borderTop: `1px solid ${Colors.border}`
      }));

      export const  ItemContent = styled(Box)(()=>({
        display : "flex",
        flexDirection:'column',
        margin:'40px',
       // width:'300px',
        textAlign:'left',
        //padding:'50px',
        paddingTop:'20px',
        paddingBottom:'20px',

       
      }));

      export const  ItemTitle = styled(Typography)(()=>({
      fontSize:'20px',
      color:Colors.backcolor,
      fontWeight:'500',
      fontFamily: "Questrial",
       
      }));

      export const  ItemLinks = styled(Typography)(()=>({
        display : "flex",
       
      }));




    export const  ActionIconsContainerMobile = styled(Box)(()=>({
       display : "flex",
       background: Colors.shaft,
       position:"fixed",
       bottom: 0,
       left: 0,
       width:'100%',
       alignItem: 'center',
       zIndex: 99,
       borderTop: `1px solid ${Colors.border}`
     }));

     export const  ActionIconsContainerDesktop = styled(Box)(()=>({
        flexGrow : 0,
        zIndex: 99,
       // marginTop:'-231px',
      }));
