

import {  ServiceContainer, ServiceContent,  WorkDescription,  } from "../../styles/style";




export default function Services(){
   

    return (
    <ServiceContainer>
      <div style={{
        marginTop:'200px'
      }}></div>
        <ServiceContent>
        
                  
                  
                 
                 
            <WorkDescription
           
            variant="subtitle">
              <p>Currently working with the NAL/Comet Group, (a family-led business conglomerate with multinational) 
                leading IT/Enterprise expansion programs for the group of companies.</p>
              <p>Currently a trustee member and president of the Maritime Information Technology Stakeholders Association 
                of Nigeria (MITSAN), an organization helping the port community setup a framework for commercialization of 
                industry’s information and implementation of government and inter-agency revenue realization programs.</p>
              <p>On the outlook, I am working with a team on a block-chain based social-trust-system that puts technologies at 
                the intersection of people, business and politics. My main strength has been in the business of SaaS, 
                building and expanding the adoption of software-as-a-service in areas of life where people find it 
                unconventional in Africa.</p>
              <p>I enjoy building companies, teams, and ideas. As a startup enthusiast, what matters to me most is creating the platform and community for teams to feel empowered to do their best work. My professional interests include strategy & operations, design thinking, and emerging technologies.</p>
              <p>I’ve been lucky to contribute to early stage startup teams in Delaware USA, Lagos Nigeria (PG LLC, JOHICE Nigeria) — doing everything from Chief Operating Officer, Tech Evangelist and a social worker.</p>
              <p>In a previous work life, I was at Microsoft Innovation Center, Nigeria, a product evangelist 
                at GoCanvas LLC USA, worked with United Nations on Conference & Trade (UNCTAD) steering committee on
                 implementation of ASYCUDA++, worked in changing roles between IT management and consulting 
                 for tech and maritime businesses.</p>
              <p>Say hi on Twitter @Paul_Royals</p>
     

            </WorkDescription>
        </ServiceContent>
        

        

        
    </ServiceContainer>

    
    )
}