import { Box } from '@mui/system';
import * as React from 'react';
import { ServiceImage } from '../styles/photo';
//import Box from '@mui/material/Box';

import { Container, Content, RowContainer } from '../styles/photo';

export default function Gallerys() {
  return (


    <Container>
      <div style={{
        marginTop:'230px'
      }}>

      </div>

      <Content >
    <RowContainer 
  
    >
      <Box style={{ flexDirection:'column'}}>
      <ServiceImage 
   
   src="/img/8.jpg">
   
   </ServiceImage>
   <p>heloo</p>

      </Box>
  

    <ServiceImage
  
   
   src="/img/5.jpg">

    </ServiceImage>

    <ServiceImage 
    src="/img/7.jpg">
    </ServiceImage>
    </RowContainer>
      </Content>
      <Content >
    <RowContainer 
  
    >
    <ServiceImage 
   
    src="/img/1.jpeg">
    </ServiceImage>

    <ServiceImage
  
   
   src="/img/2.jpeg">

    </ServiceImage>

    <ServiceImage 
    src="/img/4.jpeg">
    </ServiceImage>
    </RowContainer>
      </Content>
      <Content >
    <RowContainer 
  
    >
    <ServiceImage 
   
    src="/img/11.jpeg">
    </ServiceImage>

    <ServiceImage
  
   
   src="/img/12.jpeg">

    </ServiceImage>

    <ServiceImage 
    src="/img/3.jpeg">
    </ServiceImage>
    </RowContainer>
      </Content>
    
    </Container>
 
  
  );
}


