import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../theme";
import "@fontsource/poppins";
import "@fontsource/open-sans";
import "@fontsource/mulish";



export const ServiceContainer = styled(Box)(({theme})=>({
        display: 'flex',
        flexDirection:'column',
        //justifyContent:'center',
        padding :'50px',
        backgroundColor:Colors.whitesmoke,

        [theme.breakpoints.down("sm")]:{
          width:'100%',
          margin:0,
          padding :0,
        
      
      }

}));

export const ServiceContent = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection:'row',
        marginTop:'200px',

    justifyContent:'center',
    padding :'50px',
    alignItems:'center',
    marginLeft:'auto',
    marginRight:'auto',
    width:'100%',

    [theme.breakpoints.down("sm")]:{
      width:'100%',
      marginLeft:'-50px',
      flexDirection:'column',
    
  }

   

}));


export const ServiceTitle = styled(Typography)(({theme})=>({

   fontSize:'30px',
   fontWeight:'300',
   fontStyle:'normal',
   color:Colors.dim_gray,
   fontFamily: 'Mulish',
 
   lineHeight:'1.2em',
   letterSpacing:'0.03em',
   marginBottom:'16px',

  [theme.breakpoints.down("sm")]:{
    fontSize:'20px',
    textAlign:'center',
    paddingLeft:'10px',
    paddingRight:'10px',

}
}));



export  const StyledText = styled.p`
  color: #474747;
  margin:0 0 1.5em;
 
`;


export const ServiceDescription = styled(Typography)(({theme})=>({


  fontSize:'13px',
 
  color:Colors.black,
  marginBottom:'3em',
  fontFamily: "Open Sans",
  fontStyle:'normal',
  fontWeight:'400',
  lineHeight:'1.6em',
  letterSpacing:'0',
  textAlign:'left',
    width:'40%',

    
  [theme.breakpoints.down("sm")]:{
    fontSize:'13px',
    fontWeight:'400',
    //marginRight:'40px',
   // padding:'13px',
    width:'90%',


}
 
 
 
 }));

 export const ServiceImage = styled('img', Typography)(({src,theme})=>({
    src : `url(${src})`,
    width:' 134px',
    height:'133px',
    marginTop:'-200px',
    padding:'20px',
  
         [theme.breakpoints.down("md")]:{
            width: '350px'
   
      },
      [theme.breakpoints.down("sm")]:{
         width: '84px',
         
 
   }
 
   
  
      
  })) ;

  export const Buttonbox = styled(Button)(({theme})=>({
    borderRadius:'50px',
    fontSize:'15px',
    fontWeight:'400',
    padding :'10px',
    fontFamily:'Poppins',
    width:"200px",
    marginTop:'-50px',
    letterSpacing: '4px',
    [theme.breakpoints.down("sm")]:{
        fontSize:'10px',
        fontWeight:'600',
        width:"50%",
        padding :'10px',
        // marginLeft:'40px ',
        // marginRight:'40px ',

  }
 
      
  })) ;

  export const BoxContent = styled(Box)(({theme})=>({
    display:'flex',
    flexDirection:'row',
    [theme.breakpoints.down("sm")]:{
    
      display:'flex',
      flexDirection:'column',
  }
 
      
  })) ;