// import {  Button, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, makeStyles, Typography, useColorScheme } from "@mui/material";
import Actions from "./actions";
import { AppbarContainer, AppbarHeader,  Menulinks, } from "../../styles/appbar";
import React, { useEffect, useState } from "react";
//import { NavLink } from "react-router-dom";

import { Box } from "@mui/system";




export default function AppbarDesktop({matches}) {
  // const [navSize, setnavSize] = useState("10rem");
  // const [navColor, setnavColor] = useState("transparent");
  const [headersize, setSize] = useState("transparent");

  const listenScrollEvent = () => {
    // window.scrollY > 73 ? setnavColor(Colors.backcolor) : setnavColor("transparent");
    // window.scrollY > 73 ? setnavSize(0) : setnavSize("100px");
    window.scrollY < 20 ? setSize("3em") : setSize("1em");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  let activeStyle = {
   fontWeight: "600",
    color:'#3645F'
  };

 // let activeClassName = "underline";

 
 return(
   
        <AppbarContainer
        // style={{
          
        //  marginTop:0,
        //   backgroundColor: navColor,
        //   //height: navSize,
        //    transition: "all 1s",
        //   zIndex: '99',
        // }}
        >
        <AppbarHeader 
        style={{
         // fontSize :  '4em',
          fontSize :  headersize,
          //   //height: navSize,
             transition: "all 1s",
          //  zIndex: '99',
        }}
        
        >Paul Adeleke</AppbarHeader>

        <Box style={{
          display:'flex',
          flexDirection:'row',
          }}>
           
         
      

        <Menulinks
        style={({ isActive }) =>
        isActive ? activeStyle : undefined
          }
        to="/">

          
          ABOUT
          </Menulinks>

        <Menulinks  
        style={({ isActive }) =>
        isActive ? activeStyle : undefined
          }
        to="/work">WORK</Menulinks>
        <Menulinks  
        style={({ isActive }) =>
        isActive ? activeStyle : undefined
          }
        to="/footprint">FOOTPRINTS</Menulinks>
        <Menulinks  
        style={({ isActive }) =>
        isActive ? activeStyle : undefined
          }
        to="/thought">MY IDEOLOGIES </Menulinks>
        <Menulinks  
        style={({ isActive }) =>
        isActive ? activeStyle : undefined
          }
        to="/photo">GALLERY</Menulinks>
        <Menulinks  
        style={({ isActive }) =>
        isActive ? activeStyle : undefined
          }
        to="/musing">MUSINGS</Menulinks>

        </Box>
        
        <p
        // style={{
        //   paddingBottom: navSize,
        // }}
        ></p>
        
             
           <Actions matches={matches}/>
          
    </AppbarContainer>
    );
  
  
}


 
           
