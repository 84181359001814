import React from 'react'
import Footprints from '../components/footprint'





function Footprint() {
  return (
    <>   
 

  <Footprints/>
    
    
   
   
    </>

  )
}

export default Footprint
