import { Box,  Divider, Drawer, IconButton, List, ListItem,  ListItemText } from "@mui/material";
//import Appbar from ".";
import Actions from "./actions";

import MenuIcon from '@mui/icons-material/Menu';

import { AppbarContainer, AppbarHeader, Links } from "../../styles/appbar";
import { Colors } from "../../styles/theme";
import React from "react";


export default function AppbarMobile({matches}) {
  // const styles = theme => ({
  //   listItemText:{
  //     fontSize:'0.7em',//Insert your required size
  //     color:'red',//Insert your required size
  //   }
  // });

    const [state, setState] = React.useState({
 
        right: false,
      });

      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List 
        
          >
           
             
               <ListItem >
                 <ListItemText>
                   <Links to="/">
                    ABOUT
                   </Links>
                 </ListItemText>
               </ListItem >

               <Divider />
               <ListItem >
                 <ListItemText>
                   <Links to="/work">
                    WORK
                   </Links>
                 </ListItemText>
               </ListItem >

               <Divider />
               <ListItem >
                 <ListItemText>
                   <Links to="/footprint"
                
                   >
                    FOOTPRINT
                   </Links>
                 </ListItemText>
               </ListItem >

               <Divider />
               <ListItem >
                 <ListItemText>
                   <Links to="/thought">
                    THOUGHT SKETCHES
                   </Links>
                 </ListItemText>
               </ListItem >

               <Divider />
               <ListItem >
                 <ListItemText>
                   <Links to="/photo">
                    PHOTOGRAPHY
                   </Links>
                 </ListItemText>
               </ListItem >

               <Divider />
               <ListItem >
                 <ListItemText>
                   <Links to="/musing">
                    MUSINGS
                   </Links>
                 </ListItemText>
               </ListItem >

         
          </List>
          <Divider />
          
        </Box>
      );

    return(

        <AppbarContainer
        style={{
            flexDirection:'row',
            marginTop:0,
            backgroundColor:Colors.smokewhite,
            zIndex:'99',
            
            
        }}>
           
            <AppbarHeader  
             style={{
              fontSize:'1em'
            }}
            >
              Paul Adeleke
                </AppbarHeader>

                {/* <IconButton
               
                style={{
                    color:Colors.newgray,
                    paddingRight:'15px',
                    

                }}
                >
                <MenuIcon
                sx={{ fontSize: "50px" }}
                />
            </IconButton> */}

<div

>
      {[ 'right' ].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}

            <IconButton
            onClick={toggleDrawer(anchor, true)}
               
                style={{
                    color:Colors.newgray,
                    paddingRight:'15px',
                      marginTop:'-40px',
                  
                    

                }}
                >
               

                <MenuIcon sx={{ 
                  fontSize: "30px" }}
                 
                  />
            </IconButton
 >
             <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
    <Actions matches={matches}/>
    </AppbarContainer>
    )
   
  
}