import React from 'react'

import Thoughts from "../components/thought";



function Thought() {
  return (
    <>   
    
    <Thoughts/>
    
    
   
   
    </>

  )
}

export default Thought
