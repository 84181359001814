

import {  ServiceContainer, ServiceContent, ServiceDescription, StyledText,  } from "../../styles/thought";




export default function Musing(){
   

    return (
    <ServiceContainer
 
    >
        <ServiceContent
           style={{
            flexDirection:'column'
        }}
        >
         {/* <ServiceTitle>
                 MY IDEOLOGIES
                    </ServiceTitle>  */}
                      <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >November 3, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >Strategy is buying a bottle of fine wine when you take a lady out for 
              dinner. Tactics is getting her to drink it – Frank Muir</StyledText>
              
                 </ServiceDescription>
                      <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >November 1, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >The words to describe pain are inaccurate and imprecise – Tony Moore</StyledText>
              
                 </ServiceDescription>
                     <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >October 27, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >One man’s wage rise is another man’s price increase.
              – Harold Wilson</StyledText>
              
                 </ServiceDescription>
                     <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >October 19, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >Teach a parrot to say Demand and Supply and you have got an 
              economist – Thomas Carlyle</StyledText>
              
                 </ServiceDescription>
                     <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >October 15, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >A story is told as much by silence as by speech
              – Susan Griffin
              </StyledText>
              
                 </ServiceDescription>
                     <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >October 14</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >Wise men speak because they have something to say. Fools speak 
              because they have to say something
              – Plato</StyledText>
              
                 </ServiceDescription>
                     <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >October 11, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >Inland Revenue: We’ve got what it takes to take what you’ve got.
              – Bumper Sticker</StyledText>
              
                 </ServiceDescription>
                      <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >October 6, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >Money enables us get what we want instead of what other 
              people think we want
              – George Bernard Shaw</StyledText>
              
                 </ServiceDescription>
                      <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >October 3, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >The great tragedy of science is the slaying of a beautiful 
              hypothesis by an ugly fact – Aldons Huxley</StyledText>
              
                 </ServiceDescription>
                      <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >September 30, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >An agent is a person who is sore because an actor gets 90% of what 
              they make – Elton John</StyledText>
              
                 </ServiceDescription>
                      <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >September 27, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >A critic is a man who knows the way but can’t drive the car
              – Kenneth Tynan</StyledText>
              
                 </ServiceDescription>
                     <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >September 21, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >If you like your passport photo you are too ill to travel – Joe Pasquale</StyledText>
              
                 </ServiceDescription>
                     <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >September 19, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >For the first half of your life, people tell you what to do, for the second 
              half, they tell you what you should have done – Richard Needham</StyledText>
              
                 </ServiceDescription>

<ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >September 17, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >Everybody was saying we must have more leisure. Now they are 
              complaining they are unemployed – Prince Phillip</StyledText>
              
                 </ServiceDescription>
                      <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >September 15, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >They say life begins at forty, but I think it takes forty years to 
              realise its already started – Jeremy Hardy</StyledText>
              
                 </ServiceDescription>
                    <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >September 14, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >Anger is only one letter short of danger</StyledText>
              
                 </ServiceDescription>

<ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >September 9, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >Everybody’s business is nobody’s business and nobody’s business is my business
              – Clara Barton</StyledText>
              
                 </ServiceDescription>

                     <ServiceDescription 
          
            variant="subtitle">
                <StyledText 
                style={{
                    textAlign:'center',
                    color:'gray'
                  

                }}
                >July 20, 2022</StyledText>
                <StyledText 
                style={{
                    textAlign:'center'
                }}
                >Design growth into the product</StyledText>
                
                   </ServiceDescription>

                   <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >July 19, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >"You get in life what you have the courage to ask for." - Nancy D. Solomon</StyledText>
              
                 </ServiceDescription>

                 <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >July 17, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >"My mother said to me, 'If you become a soldier you'll be a general; if you become a monk you'll end up as the pope.' Instead, I became a painter and wound up as Picasso." - Pablo Picasso</StyledText>
              
                 </ServiceDescription>

                 <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >March 22, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >"If people are not laughing at your dreams, you're not dreaming big enough." - Grayson Marshall</StyledText>
              
                 </ServiceDescription>

                 <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >Mar 21, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >"You can tell how smart people are by the things they laugh at." - Tina Fey t</StyledText>
              
                 </ServiceDescription>

                 <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >March 11, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >
              "If people are not laughing at your dreams, you're not dreaming big enough." - Grayson Marshall</StyledText>
              
                 </ServiceDescription>

                 <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >March 12, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >
              "Think lightly of yourself and deeply of the world." - Miyamoto Musashi</StyledText>
              
                 </ServiceDescription>

                 <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >February 18, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >
              Launch and learn.</StyledText>
              
                 </ServiceDescription>

                 <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >February 01, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >
              Let's soar.</StyledText>
              
                 </ServiceDescription>

                 <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >January 29, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >
              "In times of change, learners inherit the earth, while the learned find themselves beautifully equipped to deal with a world that no longer exists.” - Eric Hoffert</StyledText>
              
                 </ServiceDescription>

                 <ServiceDescription 
          
          variant="subtitle">
              <StyledText 
              style={{
                  textAlign:'center',
                  color:'gray'
                

              }}
              >January 25, 2022</StyledText>
              <StyledText 
              style={{
                  textAlign:'center'
              }}
              >
              "The memory of work disappears like the memory of pain. All anyone remembers are results." - Dharmesh Shah</StyledText>
              
                 </ServiceDescription>
        </ServiceContent>
         </ServiceContainer>
    )
}