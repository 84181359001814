import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../theme";




export const Container = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection:'column',
    //justifyContent:'center',
    padding :'50px',
    backgroundColor:Colors.whitesmoke,
   

    [theme.breakpoints.down("sm")]:{
      width:'100%',
      margin:0,
      padding :0,
    
  
  }

}));

export const RowContainer = styled(Box)(({theme})=>({

    flexDirection:'row',
  


    [theme.breakpoints.down("sm")]:{
    //   width:'100%',
    //   margin:0,
    //   padding :0,
    flexDirection:'column',
}

}));

export const Content = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection:'row',
        marginTop:'-100px',
        

    justifyContent:'center',
    padding :'50px',
    alignItems:'center',
    marginLeft:'auto',
    marginRight:'auto',
    width:'100%',

    [theme.breakpoints.down("sm")]:{
      width:'100%',
      padding:'20px',
      marginTop:'10px',
      //marginLeft:'-50px',
      flexDirection:'column',
      marginLeft:0,
   
     
    
  }

   

}));

export const ServiceImage = styled('img', Typography)(({src,theme})=>({
    src : `url(${src})`,
    width:' 300px',
    height:'300px',
    // marginTop:'-100px',
    padding:'5px',
    objectFit:'cover',
  
         [theme.breakpoints.down("md")]:{
            width: '350px'
   
      },
      [theme.breakpoints.down("sm")]:{
        width:' 400px',
        height:'361px',
  
         }      
  })) ;

  export const Image = styled('img', Typography)(({src,theme})=>({
    src : `url(${src})`,
    width:' 200px',
    height:'200px',
          // width: '350px'
   
    //   },
      [theme.breakpoints.down("sm")]:{
        padding:'20px',
        width:' 200px',
        height:'200px',
      
  
         }      
  })) ;
