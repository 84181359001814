import React from 'react'
import Works from "../components/work";



function Work() {
  return (
    <> 
     <Works/>
    </>

  )
}

export default Work
