

import {  ServiceContainer, ServiceContent, ServiceDescription, StyledText,  } from "../../styles/thought";




export default function Thought(){
   

    return (
    <ServiceContainer
 
    >
        <ServiceContent
           style={{
            flexDirection:'column'
        }}
        >
         {/* <ServiceTitle>
                 MY IDEOLOGIES
                    </ServiceTitle>  */}
                     <ServiceDescription 
          
            variant="subtitle">
                <StyledText>This space talks about my ideologies, about life and living. It will let you in on my set of shared beliefs as formed within my social class. It presents my beliefs in resonance and contrast with others within my social class and make you understand how they influence the way I think, act and view the world.</StyledText>
                <StyledText>Often times, I have shared my beliefs within my social class and I carefully document their response. Read my blog to review a selection of people responses on political, social and cultural ideologies that I have shared which might give you a deeper sense of understanding on my ideologies.</StyledText>
                  </ServiceDescription>
        </ServiceContent>
         </ServiceContainer>
    )
}