import React from 'react'
import Musings from '../components/musing'





function Footprint() {
  return (
    <>   
 

  <Musings/>
    
    
   
   
    </>

  )
}

export default Footprint
